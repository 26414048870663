<!--
 * @Description: 售前售后
 * @Autor: JINbiao
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-02 21:07:59
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '售前售后',
          list: [
            {
              name: '说明分类',
              path: '/mall/sale/sale-category'
            },
            {
              name: '说明内容',
              path: '/mall/sale/sale-article'
            }
          ]
        }
      ]
    }
  }
}
</script>
